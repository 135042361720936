import axios from 'axios';

const fetchData = async () => {
  const url = 'https://data.abrlnp.me/index.php';
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

export default fetchData;