import './Avatar.scss';

export default function Avatar({src}) {
    return (
        <div style={{ margin: '50px'}}>
            <div className="avatar">
                <div className="avatar-inner" >
                    <img alt="Abiral's Avatar" src={src} style={{ width: '100%', borderRadius: '50%' }} />
                </div>
            </div>
        </div>
    );
}