import './Footer.scss';

export default function Footer({ socials, qr }) {
    return (
        <div className="footer">
            <div className="social-contacts">
                <div><a href={socials.linked_in.url} target="_blank" rel="noreferrer" className="social social-linkedin"><i className="icon-linkedin"></i> {socials.linked_in.text}</a></div>
                <div><a href={socials.github.url} className="social social-github" target="_blank" rel="noreferrer" ><i className="icon-github"></i> {socials.github.text}</a></div>
                <div><a href={socials.wordpress.url} className="social social-wordpress" target="_blank" rel="noreferrer"><i className="icon-wordpress"></i> {socials.wordpress.text}</a></div>

            </div>
            <div className="qr-code-area">
                <img src={qr} className="qr-code" alt="QR Code for Abiral Neupane's Contact"/>
            </div>
        </div>
    );
}