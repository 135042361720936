import Header from './components/Header';
import Avatar from './components/Avatar';
import Footer from './components/Footer';
import Loader from './components/Loader';
import './animate.scss';
import './App.scss';
import Fireflies from './components/Fireflies';
import fetchData from './utils/fetchData';
import { useState, useEffect } from 'react';

function App() {
	const [data, setData] = useState(null);
	const [isLoading, setLoading] = useState(true);
	useEffect(() => {
		const fetchDataFromAPI = async () => {
			const apiData = await fetchData();
			if (apiData) {
				setData(apiData);
			}
			setLoading(false);
		};

		fetchDataFromAPI();
	}, []);

	const style = { position: 'relative' };
	const backdropStyle={ 
		backgroundColor: '#000',
		position: 'absolute',
		width: '100%',
		height: '100%',
		opacity: 0.4,
		zIndex: -1
	};
	if(!isLoading && data && data.background_mobile) {
		style['backgroundImage'] = `url(${data.background_mobile})`;
		style['backgroundColor'] = data.primary_color;		
	}

	return (
		<div className="container">
			<div className="app fade-in" style={style}>
				<Fireflies/>
				<div className="backdrop" style={backdropStyle}></div>
				{
					isLoading && !data ? 
					<Loader /> : 
					<>
						<Header data={data} />
						<Avatar src={data.photo} />
						<Footer socials={data.contact} qr={data.qr} />
					</>
				}
			</div>
		</div>
  );
}

export default App;
